import courseSlice from './slices/courseSlice';
import reviewsSlice from './slices/reviewSlice';
import technologySlice from './slices/technologySlice';
import educationProcessSlice from './slices/educationProcessSlice';
import companiesSlice from './slices/companiesSlice';
import footerSlice from './slices/footerSlice';
import aboutUsSlice from './slices/AboutUsSlice';
import locationMapSlice from './slices/locationMapSlice';
import faqSlice from './slices/faqSlice';
import homeSlice from './slices/HomeSlice';
import VacancySlice from './slices/VacancySlice';
import trialLessonSlice from './slices/trialLessonSlice';
import advantagesSlice from './slices/advantagesSlice';
import allcoursesSlice from './slices/allcoursesSlice';
import GraduatesSlice from './slices/graduatesSlice';
import careerCenterSlice from './slices/careerCenterSlice';
import directionSlice from './slices/directionSlice';
import studentsSlice from './slices/studentsSlice';
import studentSlice from './slices/studentSlice';
import privacyPolicySlice from './slices/privacyPolicySlice';
import geeksJuniorSlice from './slices/geeksJuniorSlice';
import geeksOnlineSlice from './slices/geeksOnlineSlice';
import afterCoursesSlice from './slices/afterCoursesSlice';
import newsSlice from './slices/newsSlice';
import editStudentSlice from './slices/editStudentSlice';
import agreementSlice from './slices/agreementSlice';


export const reducerObj = {
  [ courseSlice.name ]: courseSlice.reducer,
  [ reviewsSlice.name ]: reviewsSlice.reducer,
  [ technologySlice.name ]: technologySlice.reducer,
  [ educationProcessSlice.name ]: educationProcessSlice.reducer,
  [ companiesSlice.name ]: companiesSlice.reducer,
  [ footerSlice.name ]: footerSlice.reducer,
  [ aboutUsSlice.name ]: aboutUsSlice.reducer,
  [ locationMapSlice.name ]: locationMapSlice.reducer,
  [ faqSlice.name ]: faqSlice.reducer,
  [ aboutUsSlice.name ]: aboutUsSlice.reducer,
  [ homeSlice.name ]: homeSlice.reducer,
  [ VacancySlice.name ]: VacancySlice.reducer,
  [ trialLessonSlice.name ]: trialLessonSlice.reducer,
  [ advantagesSlice.name ]: advantagesSlice.reducer,
  [ allcoursesSlice.name ]: allcoursesSlice.reducer,
  [ GraduatesSlice.name ]: GraduatesSlice.reducer,
  [ privacyPolicySlice.name ]: privacyPolicySlice.reducer,
  [ geeksOnlineSlice.name ]: geeksOnlineSlice.reducer,
  [ afterCoursesSlice.name ]: afterCoursesSlice.reducer,
  [ newsSlice.name ]: newsSlice.reducer,
  // Geek PRO
  [ careerCenterSlice.name ]: careerCenterSlice.reducer,
  [ directionSlice.name ]: directionSlice.reducer,
  [ studentsSlice.name ]: studentsSlice.reducer,
  [ studentSlice.name ]: studentSlice.reducer,
  [ editStudentSlice.name ]: editStudentSlice.reducer,
  [ agreementSlice.name ]: agreementSlice.reducer,
  //Geeks Junior
  [ geeksJuniorSlice.name ]: geeksJuniorSlice.reducer,
};
