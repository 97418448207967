import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


const name = 'agreement';

const ENDPOINTS = { agreement: '/api/v1/career_center/agreement/' };

export const getAgreement = createAsyncThunk(
  `${name}/getAgreement`,
  async (_, { extra: api }) => {
    const response = await api.get(ENDPOINTS.agreement);
    return response.data;
  },
);

const agreementSlice = createSlice({
  name: 'agreement',
  initialState: { data: null, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAgreement.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  }
});

export default agreementSlice.reducer;
